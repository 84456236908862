import { gapi } from "gapi-script";
import React, { useEffect, useState } from "react";
// import { useGoogleLogin } from "react-google-login";
import {

  getSessionDetailss,
} from "../apiServices/userHttpService/adminUserHttpService";
import { useParams } from "react-router-dom";

const GoogleCalenderSync = () => {
  const [details, setDetails] = useState();
  let { id } = useParams();
  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const { data } = await getSessionDetailss(id);
    if (!data.error) {
      setDetails(data.results.session);
      let info = data.results.session;
      addCalendarEvent(
        info?.date,
        info?.user?.time_zone,
        info?.duration,
        "test",
        info?.name
      );
    }
  };

  let CLIENT_ID =
    "815072135246-uabpflu5fqfs754dt0nnj30uggj0ulhp.apps.googleusercontent.com";
  let API_KEY = "AIzaSyBFwPdflbLmawTFRaI2P36rfrnqXNmCbcg";
  let DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  let SCOPES = "https://www.googleapis.com/auth/calendar.events";

  const addCalendarEvent = (startTime, zone, dura, address, clientName) => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      gapi.client.load("calendar", "v3");

      let timeZone = zone;
      let duration = `00:${dura}:00`; //duration of each event, here 30 minuts

      let startDate = new Date(startTime);
      let msDuration =
        (Number(duration.split(":")[0]) * 60 * 60 +
          Number(duration.split(":")[1]) * 60 +
          Number(duration.split(":")[2])) *
        1000;
      let endDate = new Date(startDate.getTime() + msDuration);
      let isoStartDate = new Date(
        startDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split(".")[0];
      let isoEndDate = new Date(
        endDate.getTime() - new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .split(".")[0];

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          let event = {
            summary: clientName, // or event name
            location: address, //where it would happen
            start: {
              dateTime: isoStartDate,
              timeZone: timeZone,
            },
            end: {
              dateTime: isoEndDate,
              timeZone: timeZone,
            },
            recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
            reminders: {
              useDefault: false,
              overrides: [{ method: "popup", minutes: 20 }],
            },
            // attendees: [{ email: "naman@techgropse.com", comment: "hello" }],
          };

          gapi.client.calendar.events
            .list({
              calendarId: "primary",
              timeMin: new Date().toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: "startTime",
            })
            .then((response) => {
              const events = response.result.items;
              console.log("EVENTS: ", events);
            });

          let request = gapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event,
          });

          request.execute((event) => {
            console.log(event);
            window.open(event.htmlLink);
          });
        });
    });
  };

  // const handleAddToCalendar = async () => {
  //     const apiKey = 'AIzaSyBFwPdflbLmawTFRaI2P36rfrnqXNmCbcg';
  //     const calendarId = 'primary'; // Use 'primary' for the user's primary calendar

  //     // Replace the following variables with your event details
  //     const event = {
  //       summary: 'Test Event Athar',
  //       location: 'Event Location',
  //       start: {
  //         dateTime: '2024-02-08T10:00:00',
  //         timeZone: 'GMT+5:30',
  //       },
  //       end: {
  //         dateTime: '2024-02-08T12:00:00',
  //         timeZone: 'GMT+5:30',
  //       },
  //     };

  //     try {
  //       const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(event),
  //       });

  //       if (response.ok) {
  //         console.log('Event added to Google Calendar successfully!');
  //       } else {
  //         console.error('Error adding event to Google Calendar:', response.statusText);
  //       }
  //     } catch (error) {
  //       console.error('Error adding event to Google Calendar:', error.message);
  //     }
  //   };

  return <div></div>;
};

export default GoogleCalenderSync;
