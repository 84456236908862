import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getDashboardCount,
  getSessionList,
  getUsers,
} from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import ClinicianHeader from "../commonComponent/clinicianHeader";
import ClinicianSidebar from "../commonComponent/clinicianSidebar";
import ClinicianUpcoming from "../videoSessionComponent/clinicianUpcomingList";
import moment from "moment";
import { MDBDataTable } from "mdbreact";

function ClinicianDashboard() {
  const [dashboard, setDashboard] = useState("");
  const [from, setFrom] = useState("");
  const [till, setTill] = useState("");
  const [filter, setFilter] = useState("");
  const [user, setUser] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },

      {
        label: "Mother",
        field: "mother",
        sort: "asc",
        width: 100,
      },
      {
        label: "Daughter",
        field: "daughter",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Name",
        field: "plan",
        sort: "asc",
        width: 100,
      },
      {
        label: "View",
        field: "view",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  useEffect(() => {
    getDashboardDetail();
  }, []);
  useEffect(() => {
    getUserListing();
  }, [filter]);

  const getUserListing = async () => {
    const formData = {
      from: from,
      till: till,
      filter: filter,
    };
    const { data } = await getUsers(formData);
    if (!data.error) {
      const newRows = [];
      console.log(data);
      data.results.users.map((list, index) => {
        const returnData = {};

        returnData.sn = index + 1;
        returnData.mother =
          list.type === "Mother" ? list?.full_name : list.partner?.full_name;
        returnData.daughter =
          list.type === "Daughter" ? list?.full_name : list.partner?.full_name;
        returnData.plan = list.planId.plan_name;
        returnData.view = (
          <>
            <Link
              className="comman_btn table_viewbtn"
              to={`/clinician/user-details/${list._id}`}
            >
              View
            </Link>
          </>
        );
        newRows.push(returnData);
      });

      setUser({ ...user, rows: newRows });
    }
  };

  const getDashboardDetail = async () => {
    const { data } = await getDashboardCount();
    if (!data.error) {
      setDashboard(data.results);
    }
  };

  return (
    <>
      {" "}
      <div className="admin_main">
        <ClinicianSidebar />

        <div className="admin_main_inner">
          <ClinicianHeader />

          <div className="admin_panel_data height_adjust">
            <div className="row dashboard_part justify-content-center">
              <div className="col-12">
                <div className="row ms-3 mb-5 justify-content-center">
                  <div className="col d-flex align-items-stretch">
                    <Link
                      to="/clinician/video-session-management"
                      className="row dashboard_box box_design me-3 w-100"
                    >
                      <div className="col-auto px-0">
                        <span className="dashboard_icon">
                          <i className="fad fa-spinner"></i>
                        </span>
                      </div>
                      <div className="col pe-0">
                        <div className="dashboard_boxcontent">
                          <h2>Upcoming Video Sessions</h2>
                          <span>{dashboard.upcomingSessionCount}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col d-flex align-items-stretch">
                    <Link
                      to="/clinician/video-session-management"
                      className="row dashboard_box box_design me-3 w-100"
                    >
                      <div className="col-auto px-0">
                        <span className="dashboard_icon">
                          <i className="far fa-play-circle"></i>
                        </span>
                      </div>
                      <div className="col pe-0">
                        <div className="dashboard_boxcontent">
                          <h2>Assigned Video Sessions</h2>
                          <span>0</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col d-flex align-items-stretch pe-0">
                    <Link
                      to="/clinician/video-session-management"
                      className="row dashboard_box box_design me-0 w-100"
                    >
                      <div className="col-auto px-0">
                        <span className="dashboard_icon">
                          <i className="fas fa-camera-movie"></i>
                        </span>
                      </div>
                      <div className="col pe-0">
                        <div className="dashboard_boxcontent">
                          <h2>Completed Video Sessions</h2>
                          <span>{dashboard.completedSessionCount}</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col-12 design_outter_comman recent_orders shadow">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>Family Assignments</h2>
                      </div>
                      <div className="col-3">
                        <form className="form-design" action="">
                          <div className="form-group mb-0 position-relative icons_set">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              name="name"
                              id="name"
                            />
                            <i className="far fa-search"></i>
                          </div>
                        </form>
                      </div>
                    </div>
                    <form className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                      <div class="form-group col-2 multiple_select_design mb-0">
                        <label for="">Filter Families</label>
                        <select
                          class="form-control"
                          id="type"
                          name="type"
                          onChange={(e) => setFilter(e.target.value)}
                        >
                          <option value="" selected>
                            Select..
                          </option>
                          <option value={1}>Active Families</option>
                          <option value={2}>Inactive Families</option>
                        </select>
                      </div>
                      <div className="form-group mb-0 col">
                        <label for="">From</label>
                        <input
                          type="date"
                          className="form-control"
                          id="from"
                          onChange={(e) => setFrom(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col">
                        <label for="">To</label>
                        <input
                          type="date"
                          className="form-control"
                          id="till"
                          onChange={(e) => setTill(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-auto">
                        <Link
                          className="comman_btn"
                          onClick={() => getUserListing()}
                          to=""
                        >
                          Search
                        </Link>
                      </div>
                    </form>
                    <div class="row">
                      <div class="col-12 comman_table_design px-0">
                        <div class="table-responsive">
                          <MDBDataTable
                            bordered
                            hover
                            data={user}
                            noBottomColumns
                            sortable
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicianDashboard;
