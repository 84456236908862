import React, { useEffect, useState } from "react";
import {
  editClinician,
  getClinicianData,
} from "../../apiServices/clinicianPanelHttpServices/loginHttpService/clinicianLoginHttpService";
import { toast } from "react-toastify";
import moment from "moment";
import {
  editClinicianFromAdmin,
  getClinicianDataFromAdmin,
} from "../../apiServices/clinicianHttpService/adminClinicianHttpService";
import { useParams } from "react-router-dom";

function SlotsCalenderAdmin({ clinicianData }) {
  const [clinician, setClinician] = useState("");
  const [activeWeek, setActiveWeek] = useState(1);
  const [weeksArray, setWeeksArray] = useState([]);
  const [weeksArrayDummy, setWeeksArrayDummy] = useState([]);
  let { id } = useParams();

  console.log(clinicianData);

  useEffect(() => {
    initiateCalender();
  }, []);

  const initiateCalender = async () => {
    const currentDate = moment();
    const startDate = currentDate.clone().startOf("isoWeek"); // Find the first day of the current ISO week
    const endDate = startDate.clone().add(1, "year").endOf("year"); // One year after the start date

    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    const timeArray = [
      {
        time1: "08:30 AM",
        time2: "09:30 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "09:30 AM",
        time2: "10:30 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "10:30 AM",
        time2: "11:30 AM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "11:30 AM",
        time2: "12:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "12:30 PM",
        time2: "01:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "01:30 PM",
        time2: "02:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "02:30 PM",
        time2: "03:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "03:30 PM",
        time2: "04:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "04:30 PM",
        time2: "05:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "05:30 PM",
        time2: "06:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "06:30 PM",
        time2: "07:30 PM",
        selected: false,
        isScheduled: false,
      },
      {
        time1: "07:30 PM",
        time2: "08:30 PM",
        selected: false,
        isScheduled: false,
      },
    ];

    const updatedWeeksArray = [];

    for (
      let currentDay = startDate.clone();
      currentDay.isSameOrBefore(endDate);
      currentDay.add(1, "week")
    ) {
      const weekStart = currentDay.clone();
      const weekEnd = currentDay.clone().endOf("isoWeek");

      // Adjust week number based on the current date
      const weekNumber = currentDay.diff(startDate, "weeks") + 1;

      const weekDays = [];
      for (
        let day = weekStart.clone();
        day.isSameOrBefore(weekEnd);
        day.add(1, "day")
      ) {
        const dayOfWeek = daysOfWeek[day.day()];
        const timeSlots = timeArray.map((timeSlot) => ({
          ...timeSlot,
          day: dayOfWeek,
        }));

        const formattedDate = day.format("DD MMM YYYY");

        weekDays.push({ date: formattedDate, day: dayOfWeek, timeSlots });
      }

      updatedWeeksArray.push({ weekNumber, days: weekDays });
    }
    // setWeeksArray(updatedWeeksArray);
    getClinicainValue(updatedWeeksArray);
  };

  const getClinicainValue = async (values) => {
    const { data } = await getClinicianDataFromAdmin(id);
    const yesterday = moment().subtract(1, "day");
    if (!data.error) {
      setClinician(data.results.clinician);

      let rec_slots = data.results.clinician.rec_time_slots;
      let allocated_slots = data.results.allocated_slots;
      let time_slots = data?.results?.clinician?.all_time_slots;

      const getIsScheduled = (day, time) => {
        let newRec = false;
        allocated_slots.some((val) => {
          if (val?.date === day && val.time_slots[0] === time) {
            newRec = true;
          }
        });
        return newRec;
      };

      const getDaywiseTime = (day, time, select) => {
        let newRec = undefined;
        rec_slots?.some((item) => {
          return item.some((val) => {
            if (val?.day === day && val.time1 === time) {
              newRec = val.selected;
              return true; // break out of the loop once newRec is true
            }
          });
        });
        if (select === true) {
          newRec = select;
          return newRec;
        }
        return newRec;
      };

      if (time_slots?.length) {
        time_slots = time_slots.map((item) => {
          item.days = item.days.map((itm) => {
            itm.timeSlots = itm.timeSlots.map((nw) => {
              nw.selected = getDaywiseTime(itm?.day, nw.time1, nw.selected);
              nw.isScheduled = getIsScheduled(itm?.date, nw.time1);
              return nw;
            });
            return itm;
          });
          return item;
        });
        let tempSLots = time_slots?.filter((item) => {
          return moment(item.date, "DD MMM YYYY").isBefore(yesterday, "day");
        });

        console.log(tempSLots, "popo");
        setWeeksArray(time_slots);

      } else {
        let week_arry = values;
        week_arry = week_arry.map((item) => {
          item.days = item.days.map((itm) => {
            itm.timeSlots = itm.timeSlots.map((nw) => {
              // Check if rec_slots has a selected slot for the same day and time
              nw.selected = getDaywiseTime(itm?.day, nw.time1, nw.selected);
              nw.isScheduled = getIsScheduled(itm?.date, nw.time1);
              return nw;
            });
            return itm;
          });
          return item;
        });
        setWeeksArray(week_arry);
      }
    }
  };

  console.log(weeksArray, "only");

  const onSave = async (reset) => {
    const formData = new FormData();
    let SelectedTimeArrays = [];

    weeksArray?.map((item) => {
      item?.days?.map((itm) => {
        itm?.timeSlots?.map((val) => {
          if (val.selected === true) {
            SelectedTimeArrays.push({
              date: itm?.date,
              time_slots: [val.time1, val.time2],
            });
          }
        });
      });
    });

    console.log(SelectedTimeArrays, "newSlected array");

    if (weeksArray || clinician?.time_slots) {
      reset
        ? formData.append("all_time_slots", [])
        : formData.append("all_time_slots", JSON.stringify(weeksArray));
      formData.append("time_slots", JSON.stringify(SelectedTimeArrays));
    }
    formData.append("email", clinicianData?.email);
    formData.append("name", clinicianData?.name);
    formData.append("phone_number", clinicianData?.phone_number);
    formData.append("experience", clinicianData?.experience);
    formData.append("license", clinicianData?.license);
    formData.append("bio", clinicianData?.bio);
    formData.append("expertise", JSON.stringify(clinicianData?.expertise));

    const response = await editClinicianFromAdmin(formData);

    if (!response.data.error) {
      toast.success(response.data.message);
      getClinicainValue();
    } else toast.error(response.data.message);
  };

  const handleNext = () => {
    activeWeek == weeksArray?.length
      ? setActiveWeek(activeWeek - 1)
      : setActiveWeek(activeWeek + 1);
  };
  const handlePrev = () => {
    activeWeek == 1 ? setActiveWeek(1) : setActiveWeek(activeWeek - 1);
  };

  let handleTime = (I, i, e, val, item) => {
    let newFormValues = [...weeksArray];
    newFormValues[I].days[i].timeSlots[e].selected = val;
    setWeeksArray(newFormValues);
  };

  return (
    <>
      {" "}
      <div class="row justify-content-center">
        <div class="col-md-12">
          <form class="form-design ">
            <br />
            <div class=" col-12">
              <label className="fs-4 fw-bold" for="">
                Slots Calender{" "}
                <i
                  onClick={() => onSave("reset")}
                  style={{
                    cursor: "pointer",
                  }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Click to Reset Calender"
                  class="fa fa-refresh"
                  aria-hidden="true"
                ></i>
              </label>
              <div class=" col-12 text-center  border rounded reccuring_days">
                <div className="w-100">
                  <div className=" slots-table">
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      onClick={() => handlePrev()}
                      title="Next Week"
                    >
                      <i class="fa fa-arrow-left "></i>
                    </a>
                    <h1>Select Time Slots</h1>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      onClick={() => handleNext()}
                      title="Click this button to view this Next page"
                    >
                      <i class="fa fa-arrow-right"></i>
                    </a>
                  </div>

                  <div className=" border">
                    {(weeksArray.length ? weeksArray : weeksArrayDummy)?.map(
                      (weeks, ind) =>
                        weeks?.weekNumber === activeWeek && (
                          <table className="table mb-0 ">
                            <thead>
                              <tr
                                style={{
                                  backgroundColor: "#000",
                                  color: "#fff",
                                }}
                              >
                                <th>Monday</th>
                                <th>Tuesday</th>
                                <th>Wednesday</th>
                                <th>Thursday</th>
                                <th>Friday</th>
                                <th>Saturday</th>
                                <th>Sunday</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                {weeks?.days?.map((item, index) => (
                                  <td className=" text-center  justify-content-center ">
                                    <tr className="month_header text-center">
                                      {item?.date}
                                    </tr>

                                    {item?.timeSlots?.map((itm, id) =>
                                      itm?.selected ? (
                                        <>
                                          {itm?.isScheduled === true ? (
                                            <li
                                              onClick={() =>
                                                handleTime(
                                                  ind,
                                                  index,
                                                  id,
                                                  false,
                                                  itm
                                                )
                                              }
                                              style={{
                                                backgroundColor: `${"#5cb85c"}`,
                                                color: "#fff",
                                              }}
                                            >
                                              <time>
                                                {itm?.time1} -{itm?.time2}
                                              </time>
                                            </li>
                                          ) : (
                                            <li
                                              onClick={() =>
                                                handleTime(
                                                  ind,
                                                  index,
                                                  id,
                                                  false,
                                                  itm
                                                )
                                              }
                                              className="selected_time"
                                            >
                                              <time>
                                                {itm?.time1} -{itm?.time2}
                                              </time>
                                            </li>
                                          )}
                                        </>
                                      ) : (
                                        <li
                                          style={{
                                            backgroundColor: `${
                                              itm?.isScheduled === true
                                                ? "#5cb85c"
                                                : "#fff"
                                            }`,
                                            color: `${
                                              itm?.isScheduled === true
                                                ? "#fff"
                                                : "#000"
                                            }`,
                                          }}
                                          onClick={() =>
                                            handleTime(
                                              ind,
                                              index,
                                              id,
                                              true,
                                              itm
                                            )
                                          }
                                        >
                                          <time>
                                            {itm?.time1} -{itm?.time2}
                                          </time>
                                        </li>
                                      )
                                    )}
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12 text-center mt-4">
                <p>
                  *After selecting recurring slots must click save button below
                </p>
                <a class="comman_btn" onClick={() => onSave()}>
                  Please Save Slots
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SlotsCalenderAdmin;
