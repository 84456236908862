import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSessionData } from "../../apiServices/userHttpService/adminUserHttpService";
import AdminHeader from "../commonComponent/adminHeader";
import AdminSidebar from "../commonComponent/adminSidebar";
import { usePDF } from "react-to-pdf";

function ViewCompleted() {
  const [completedData, setCompletedData] = useState("");
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  let { id } = useParams();

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    const { data } = await getSessionData(id);
    if (!data.error) {
      console.log(data);
      setCompletedData(data.results.session);
    }
  };

  return (
    <>
      {" "}
      <div class="admin_main">
        <AdminSidebar />
        <div class="admin_main_inner">
          <AdminHeader />
          <div class="admin_panel_data height_adjust">
            <div class="row user-details-part upcoming_view justify-content-center">
              <div class="col-12 mb-4 main_heading text-center">
                <h1>Completed</h1>
              </div>

              <div
                class="col-12 design_outter_comman shadow mb-4"
                ref={targetRef}>
                <div class="row comman_header justify-content-between">
                  <div class="col">
                    <h2>Clinician Documentation</h2>
                    <span>{moment(completedData.date).format("LLL")}</span>
                  </div>
                  <div class=" bg-white col-auto text-end p-2 border rounded">
                    <img src="/assets/img/logo.png" alt="" width={95}  />
                  </div>
                </div>

                {completedData.plan_type === 1 ? (
                  <div class="row p-4">
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Clinician feedback</span>
                        <div class="documentation_box_data">
                          <p>{completedData.feedback}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Focus of Session</span>
                        <div class="documentation_box_data">
                          <p>{completedData.focus_of_session}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Progress toward Goals</span>
                        <div class="documentation_box_data">
                          <p>{completedData.progress_toward_goals}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Safety Concerns</span>
                        <div class="documentation_box_data">
                          <p>{completedData.safety_concerns}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Plan</span>
                        <div class="documentation_box_data">
                          <p>{completedData.plan}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr"> Completion Date</span>
                        <div class="documentation_box_data">
                          <p>
                            {moment(completedData.completion_date).format(
                              "LLL"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Clinician Signature</span>

                        <div class="user_response_box me-3">
                          <img src={completedData.clinician_signature} alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Comment</span>
                        <div class="documentation_box_data">
                          <p>{completedData.change_reason}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Addendum Date/Time</span>
                        <div class="documentation_box_data">
                          <p>{moment(completedData.sign_date).format("LLL")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : completedData.plan_type === 2 &&
                  (completedData.number === 2 || completedData.number === 3) ? (
                  <div class="row p-4">
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          How would you describe your mother-daughter
                          relationship?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.mother_daughter_relationship}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          What are your goals for joining Passport Journeys?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.goals}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          What things work well within the relationship?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.well_within_relationship}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          What would you like to see improve in the
                          relationship?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.improve}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          What do you hope for in your mother-daughter
                          relationship?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.hope}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          What do you identify as the biggest difficulty in the
                          relationship?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.difficulty_in_relationship}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          What is the most important change you would like to
                          achieve in going on this journey?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.change}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Do you currently see a therapist? If no, what support
                          do you have in place?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.therapy_history}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          What was helpful or not about your previous
                          experience?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.previous_experience}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Is there other information you think is important for
                          me to know before moving forward?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.other_info}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Review of red flags: (alcohol and/or drug use, active
                          abuse in relationship, history of abuse, recent or
                          unprocessed trauma)
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.red_flags_review}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Mental health screen: (past psychiatric history,
                          suicidality, violence, pertinent medical history){" "}
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.mental_health_screen}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr"> Social history</span>
                        <div class="documentation_box_data">
                          <p>{completedData.social_history}</p>
                        </div>
                      </div>
                    </div>{" "}
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Other pertinent information
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.other_pertinent_information}</p>
                        </div>
                      </div>
                    </div>{" "}
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Observations: (mental status exam)
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.observations}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Assessment: (suitability for this type of therapy)
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.assessment}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Plan</span>
                        <div class="documentation_box_data">
                          <p>{completedData.plan}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr"> Session Attendees</span>
                        <div class="documentation_box_data">
                          <p>{completedData.attendees}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr"> Completion Date</span>
                        <div class="documentation_box_data">
                          <p>
                            {moment(completedData.completion_date).format(
                              "LLL"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Clinician Signature</span>

                        <div class="user_response_box me-3">
                          <img src={completedData.clinician_signature} alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Comment</span>
                        <div class="documentation_box_data">
                          <p>{completedData.change_reason}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Addendum Date/Time</span>
                        <div class="documentation_box_data">
                          <p>{moment(completedData.sign_date).format("LLL")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : completedData.plan_type === 2 &&
                  completedData.number === 1 ? (
                  <div class="row p-4">
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          What prompted you to start this journey?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.start_reason}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          What are your goals in starting Passport Journeys?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.goals}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          Have either of you been in counseling/therapy before
                          either separately or together?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.therapy_history}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Orientation and Questions</span>
                        <div class="documentation_box_data">
                          <p>{completedData.focus_of_session}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Safety Concerns</span>
                        <div class="documentation_box_data">
                          <p>{completedData.safety_concerns}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Observations and Assessment
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.observations_and_assessment}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Plan</span>
                        <div class="documentation_box_data">
                          <p>{completedData.plan}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr"> Session Attendees</span>
                        <div class="documentation_box_data">
                          <p>{completedData.attendees}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr"> Completion Date</span>
                        <div class="documentation_box_data">
                          <p>
                            {moment(completedData.completion_date).format(
                              "LLL"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Clinician Signature</span>

                        <div class="user_response_box me-3">
                          <img src={completedData.clinician_signature} alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Comment</span>
                        <div class="documentation_box_data">
                          <p>{completedData.change_reason}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Addendum Date/Time</span>
                        <div class="documentation_box_data">
                          <p>{moment(completedData.sign_date).format("LLL")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : completedData.plan_type === 2 &&
                  completedData.number === 4 ? (
                  <div class="row p-4">
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          Clinician Feedback: Is the pair a good fit for
                          Passport Journeys currently? If no, what
                          recommendations were made?
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.feedback}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          Feedback from mother-daughter pair
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.feedback_from_pair}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Goals for treatment</span>
                        <div class="documentation_box_data">
                          <p>{completedData.goals}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Safety Concerns</span>
                        <div class="documentation_box_data">
                          <p>{completedData.safety_concerns}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">
                          {" "}
                          Other pertinent information:
                        </span>
                        <div class="documentation_box_data">
                          <p>{completedData.other_pertinent_information}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Plan</span>
                        <div class="documentation_box_data">
                          <p>{completedData.plan}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr"> Session Attendees</span>
                        <div class="documentation_box_data">
                          <p>{completedData.attendees}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr"> Completion Date</span>
                        <div class="documentation_box_data">
                          <p>
                            {moment(completedData.completion_date).format(
                              "LLL"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Clinician Signature</span>

                        <div class="user_response_box me-3">
                          <img src={completedData.clinician_signature} alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Comment</span>
                        <div class="documentation_box_data">
                          <p>{completedData.change_reason}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mb-4 px-0 mt-3">
                      <div class="clinician_documentation_box border">
                        <span class="headerrr">Addendum Date/Time</span>
                        <div class="documentation_box_data">
                          <p>{moment(completedData.sign_date).format("LLL")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row justify-content-center">
                <div className="col-4 text-center">
                  <button class="comman_btn2  " onClick={() => toPDF()}>
                    Download PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewCompleted;
