import React, { useEffect, useState } from "react";
import AdminHeader from "../commonComponent/adminHeader";
import AdminSidebar from "../commonComponent/adminSidebar";
import { Link, useParams } from "react-router-dom";
import {
  changeUserStatus,
  deleteUserByEmail,
  getUserData,
  getUserPlanHistory,
  swapUserRole,
  updatePartner,
} from "../../apiServices/userHttpService/adminUserHttpService";
import moment from "moment";
import { MDBDataTable } from "mdbreact";

function UserDetails() {
  const [user, setUser] = useState("");
  const [indexS1, setIndexS1] = useState(0);
  const [indexS2, setIndexS2] = useState(0);
  const [indexS3, setIndexS3] = useState(0);
  const [indexS4, setIndexS4] = useState(0);
  const [index2S1, setIndex2S1] = useState(0);
  const [index2S2, setIndex2S2] = useState(0);
  const [index2S3, setIndex2S3] = useState(0);
  const [index2S4, setIndex2S4] = useState(0);
  const [index3, setIndex3] = useState(0);
  const [index4, setIndex4] = useState(0);
  const [email, setEmail] = useState("");
  const [from, setFrom] = useState("");
  const [till, setTill] = useState("");
  const [plan, setPlan] = useState({
    columns: [
      {
        label: "S.No.",
        field: "sn",
        sort: "asc",
        width: 150,
      },
      {
        label: "Plan Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date Of Purchase",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Valid Till",
        field: "till",
        sort: "asc",
        width: 100,
      },
      {
        label: "Plan Price",
        field: "price",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });

  let { id } = useParams();

  useEffect(() => {
    getDetail();
    getPlanListing();
  }, []);

  const getDetail = async () => {
    const { data } = await getUserData(id);
    if (!data.error) {
      setUser(data.results.user);
    }
  };
  const changeStatus = async () => {
    const { data } = await changeUserStatus(id);
    if (!data.error) {
      await getDetail();
    }
  };

  const getPlanListing = async () => {
    const formData = {
      userId: id,
      from: from,
      till: till,
    };
    const { data } = await getUserPlanHistory(formData);
    if (!data.error) {
      const newRows = [];
      console.log(data);
      data.results.plans.map((list, index) => {
        const returnData = {};
        returnData.sn = index + 1;
        returnData.name = list?.plan?.plan_name;

        returnData.date = moment(list.createdAt).format("L");
        returnData.till = moment(list.valid_till).format("L");
        returnData.price = list?.plan?.plan_price;

        newRows.push(returnData);
      });

      setPlan({ ...plan, rows: newRows });
    }
  };

  const editEmail = async () => {
    const formData = {
      userId: user?._id,
      email: email,
    };
    const { data } = await updatePartner(formData);
    if (!data.error) {
      await getDetail();
    }
  };

  const deletePartner = async (email) => {
    const formData = {
      email: email,
    };
    const { data } = await deleteUserByEmail(formData);
    if (!data.error) {
      await getDetail();
    }
  };

  const swapRole = async () => {
    const { data } = await swapUserRole(id);
    if (!data.error) {
      await getDetail();
    }
  };

  return (
    <>
      {" "}
      <div className="admin_main">
        <AdminSidebar />
        <div className="admin_main_inner">
          <AdminHeader />
          <div className="admin_panel_data height_adjust">
            <div className="row user-details-part justify-content-center">
              <div className="col-12">
                <div className="row mx-0">
                  <div className="col-12 design_outter_comman shadow mb-4">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>User's Information</h2>
                      </div>
                    </div>
                    <form className="row mx-0 align-items-start justify-content-center form-design position-relative p-4 py-5">
                      <div className="check_toggle">
                        <input
                          type="checkbox"
                          id="check"
                          class="d-none"
                          checked={user?.status}
                          onChange={() => changeStatus(user?._id)}
                        />
                        <label for="check"></label>
                      </div>
                      <div className="col-3">
                        <div className="row">
                          <div className="form-group col-12 mb-2">
                            <div className="userinfor_box text-center">
                              <span className="user_imgg">
                                <img src={user?.profile_image} alt="" />
                              </span>
                              <strong>
                                {user?.full_name}
                                <div className="sub_name">({user?.type})</div>
                              </strong>
                            </div>
                          </div>
                          <div className="form-group col-12 text-center mb-2">
                            <label className="mb-0 text-center" for="">
                              Registration Date:{" "}
                              {moment(user?.createdAt).format("L")}
                            </label>
                          </div>

                          <div className="form-group col-12 text-center mb-2">
                            <Link
                              to=""
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              <div class="comman_btn2 ">Change Role</div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col px-4">
                        <div className="row">
                          <div className="form-group col-12">
                            <label for="">
                              Pairing Member ({user?.partner?.type}):
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={
                                user?.partner?.full_name ?? user.partner_name
                              }
                              name="name"
                              id="name"
                            />
                          </div>
                          <div className="form-group col-12">
                            <label
                              for=""
                              className="d-flex justify-content-between"
                            >
                              Pairing Member Email:{" "}
                              {!user?.partner?.email ? (
                                <span>
                                  {" "}
                                  <Link to="" onClick={() => editEmail()}>
                                    <i className="fas fa-edit"></i>
                                  </Link>
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  <Link
                                    to=""
                                    onClick={() =>
                                      deletePartner(user?.partner?.email)
                                    }
                                  >
                                    <i className="fas fa-trash"></i>
                                  </Link>
                                </span>
                              )}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                user?.partner?.email ?? user.partner_email
                              }
                              key={user?.partner?.email ?? user.partner_email}
                              name="name"
                              id="name"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-group col-12 mb-0">
                            <label for="">Relationship: </label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.relationship}
                              name="name"
                              id="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col pe-5">
                        <div className="row">
                          <div className="form-group col-12">
                            <label for="">Mobile Number:</label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.phone_number}
                              name="name"
                              id="name"
                            />
                          </div>
                          <div className="form-group col-12">
                            <label for="">Email Id: </label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.email}
                              name="name"
                              id="name"
                            />
                          </div>
                          {user.appleId ? (
                            <div className="form-group col-12 mb-0">
                              <label for="">Apple Id: </label>
                              <input
                                type="text"
                                className="form-control"
                                value={user.appleId}
                                name="name"
                                id="name"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  {user?.mother_telehealth_consent?.name ||
                  user?.daughter_telehealth_consent?.name ||
                  user?.mother_npp?.name1 ||
                  user?.daughter_npp?.name1 ? (
                    <div class="col-12 design_outter_comman shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col-auto">
                          <h2>Forms Signed by Mother & Daughter</h2>
                        </div>
                      </div>
                      <form class="form-design py-4 px-3 help-support-form row align-items-end justify-content-between">
                        <div className="row">
                          {user?.mother_telehealth_consent?.name ? (
                            <div className="col-6">
                              <a
                                href={`/admin/telehealth-consent-form/${id}/Mother`}
                                class=""
                                target="_blank"
                              >
                                <div class="comman_btn2 me-3">
                                  Telehealth Consent By Mother
                                </div>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {user?.mother_npp?.name1 ? (
                            <div className="col-6 ">
                              <a
                                href={`/admin/notice-of-privacy-policies-form/${id}/Mother`}
                                class=""
                                target="_blank"
                              >
                                <div class="comman_btn2 me-3">
                                  NPP & Practice Policies By Mother
                                </div>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {user?.daughter_telehealth_consent?.name ? (
                            <div className="col-6 mt-4">
                              <a
                                href={`/admin/telehealth-consent-form/${id}/Daughter`}
                                class=""
                                target="_blank"
                              >
                                <div class="comman_btn2 me-3">
                                  Telehealth Consent By Daughter
                                </div>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}

                          {user?.daughter_npp?.name1 ? (
                            <div className="col-6 mt-4">
                              <a
                                href={`/admin/notice-of-privacy-policies-form/${id}/Daughter`}
                                class=""
                                target="_blank"
                              >
                                <div class="comman_btn2 me-3">
                                  NPP & Practice Policies By Daughter
                                </div>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-12 design_outter_comman recent_orders shadow mb-4">
                    <div className="row comman_header justify-content-between">
                      <div className="col-auto">
                        <h2>Plan Purchase History</h2>
                      </div>
                    </div>
                    <form
                      className="form-design py-4 px-3 help-support-form row align-items-end justify-content-between"
                      action=""
                    >
                      <div className="form-group mb-0 col-5">
                        <label for="">From</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setFrom(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-5">
                        <label for="">To</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setTill(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-0 col-auto">
                        <Link
                          className="comman_btn"
                          onClick={() => getPlanListing()}
                          to=""
                        >
                          Search
                        </Link>
                      </div>
                    </form>
                    <div className="row">
                      <div className="col-12 comman_table_design px-0">
                        <div className="table-responsive">
                          <MDBDataTable
                            bordered
                            hover
                            data={plan}
                            noBottomColumns
                            sortable
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {user?.answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            Monthly Plan's Question & Answer by {user.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {user?.answers?.map((item, index) => {
                                return (
                                  <button
                                    class={
                                      index === indexS1
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    id={`nav-${index}-tab`}
                                    data-bs-toggle="tab"
                                    data-bs-target={`#nav-${index}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`nav-${index}`}
                                    aria-selected="true"
                                    onClick={() => setIndexS1(index)}
                                  >
                                    {index + 1}
                                  </button>
                                );
                              })}
                            </div>
                          </nav>
                          {user.answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${indexS1}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${indexS1}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      user?.answers[indexS1]?.questionId
                                        ?.question
                                    }
                                  </h3>

                                  {user?.answers[
                                    indexS1
                                  ]?.questionId?.options?.map((item, index) => {
                                    console.log(
                                      user.answers[indexS1].answer,
                                      item
                                    );
                                    return (
                                      <div class="form-group col-auto custom_radio">
                                        <input
                                          type="radio"
                                          id={`radio1${user?.answers[indexS1]?.questionId}${index}`}
                                          name="radio1"
                                          class="d-none"
                                          checked={
                                            item ===
                                            user.answers[indexS1].answer
                                          }
                                        />
                                        <label
                                          for={`radio1${user?.answers[indexS1]?.questionId}${index}`}
                                        >
                                          {item}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {user?.answers_3month?.length ? (
                        <div class="row">
                          <label>Question & Answer 3rd Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.answers_3month?.map((item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS2
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS2(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                })}
                              </div>
                            </nav>
                            {user.answers_3month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${indexS2}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${indexS2}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.answers_3month[indexS2]
                                          ?.questionId?.question
                                      }
                                    </h3>

                                    {user?.answers_3month[
                                      indexS2
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.answers_3month[indexS2].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.answers_3month[indexS2]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.answers_3month[indexS2]
                                                  .answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.answers_3month[indexS2]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {user?.answers_6month?.length ? (
                        <div class="row">
                          <label>Question & Answer 6th Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.answers_9month?.map((item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS3
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS3(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                })}
                              </div>
                            </nav>
                            {user?.answers_6month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${indexS3}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${indexS3}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.answers[indexS3]?.questionId
                                          ?.question
                                      }
                                    </h3>

                                    {user?.answers_6month[
                                      indexS3
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user.answers_6month[indexS3].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.answers_6month[indexS3]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.answers_6month[indexS3]
                                                  .answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.answers_6month[indexS3]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {user?.answers_9month?.length ? (
                        <div class="row">
                          <label>Question & Answer 9th Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.answers_9month?.map((item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === indexS4
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndexS4(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                })}
                              </div>
                            </nav>
                            {user?.answers_9month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${indexS4}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${indexS4}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.answers[indexS4]?.questionId
                                          ?.question
                                      }
                                    </h3>

                                    {user?.answers_9month[
                                      indexS4
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.answers_9month[indexS4].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.answers_9month[indexS4]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.answers_9month[indexS4]
                                                  .answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.answers_9month[indexS4]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {user?.partner?.answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            Monthly Plan's Question & Answer by{" "}
                            {user?.partner?.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {user?.partner.answers?.map((item, index) => {
                                return (
                                  <button
                                    class={
                                      index === index2S1
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    id={`nav-${index}-tab`}
                                    data-bs-toggle="tab"
                                    data-bs-target={`#nav-${index}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`nav-${index}`}
                                    aria-selected="true"
                                    onClick={() => setIndex2S1(index)}
                                  >
                                    {index + 1}
                                  </button>
                                );
                              })}
                            </div>
                          </nav>
                          {user?.partner?.answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index2S1}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index2S1}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      user?.partner.answers[index2S1]
                                        ?.questionId?.question
                                    }
                                  </h3>

                                  {user?.partner.answers[
                                    index2S1
                                  ]?.questionId?.options?.map((item, index) => {
                                    console.log(
                                      user.partner.answers[index2S1].answer,
                                      item
                                    );
                                    return (
                                      <div class="form-group col-auto custom_radio">
                                        <input
                                          type="radio"
                                          id={`radio1${user?.partner.answers[index2S1]?.questionId}${index}`}
                                          name="radio1"
                                          class="d-none"
                                          checked={
                                            item ===
                                            user.partner.answers[index2S1]
                                              .answer
                                          }
                                        />
                                        <label
                                          for={`radio1${user?.partner.answers[index2S1]?.questionId}${index}`}
                                        >
                                          {item}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </form>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      {user?.partner?.answers_3month?.length ? (
                        <div class="row">
                          <label>Question & Answer 3rd Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.partner?.answers_3month?.map(
                                  (item, index) => {
                                    return (
                                      <button
                                        class={
                                          index === index2S2
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        id={`nav-${index}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-${index}`}
                                        aria-selected="true"
                                        onClick={() => setIndex2S2(index)}
                                      >
                                        {index + 1}
                                      </button>
                                    );
                                  }
                                )}
                              </div>
                            </nav>
                            {user?.partner?.answers_3month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${index2S2}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${index2S2}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.partner?.answers_3month[index2S2]
                                          ?.questionId?.question
                                      }
                                    </h3>

                                    {user?.partner?.answers_3month[
                                      index2S2
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.partner?.answers_3month[
                                            index2S2
                                          ].answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.partner?.answers_3month[index2S2]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.partner?.answers_3month[
                                                  index2S2
                                                ].answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.partner?.answers_3month[index2S2]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {user?.partner?.answers_6month?.length ? (
                        <div class="row">
                          <label>Question & Answer 6th Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.partner?.answers_6month?.map(
                                  (item, index) => {
                                    return (
                                      <button
                                        class={
                                          index === index2S3
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        id={`nav-${index}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-${index}`}
                                        aria-selected="true"
                                        onClick={() => setIndex2S3(index)}
                                      >
                                        {index + 1}
                                      </button>
                                    );
                                  }
                                )}
                              </div>
                            </nav>
                            {user?.partner?.answers_6month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${index2S3}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${index2S3}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.partner?.answers_6month[index2S3]
                                          ?.questionId?.question
                                      }
                                    </h3>

                                    {user?.partner?.answers_6month[
                                      index2S3
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.answers_6month[index2S3]
                                            ?.answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.partner?.answers_6month[index2S3]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.partner?.answers_6month[
                                                  index2S3
                                                ].answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.partner?.answers_6month[index2S3]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {user?.partner?.answers_9month?.length ? (
                        <div class="row">
                          <label>Question & Answer 9th Month</label>
                          <div class="col-12 user_tabbing py-4 px-4">
                            <nav>
                              <div
                                class="nav nav-tabs d-flex justify-content-start border-0"
                                id="nav-tab"
                                role="tablist"
                              >
                                {user?.partner?.answers_9month?.map(
                                  (item, index) => {
                                    return (
                                      <button
                                        class={
                                          index === index2S4
                                            ? "nav-link active"
                                            : "nav-link"
                                        }
                                        id={`nav-${index}-tab`}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#nav-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`nav-${index}`}
                                        aria-selected="true"
                                        onClick={() => setIndex2S4(index)}
                                      >
                                        {index + 1}
                                      </button>
                                    );
                                  }
                                )}
                              </div>
                            </nav>
                            {user?.partner?.answers_9month ? (
                              <div class="tab-content mt-4" id="nav-tabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id={`nav-${index2S4}`}
                                  role="tabpanel"
                                  aria-labelledby={`nav-${index2S4}-tab`}
                                >
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.answers[index2S4]?.questionId
                                          ?.question
                                      }
                                    </h3>

                                    {user?.partner?.answers_9month[
                                      index2S4
                                    ]?.questionId?.options?.map(
                                      (item, index) => {
                                        console.log(
                                          user?.answers_9month[index2S4]
                                            ?.answer,
                                          item
                                        );
                                        return (
                                          <div class="form-group col-auto custom_radio">
                                            <input
                                              type="radio"
                                              id={`radio1${user?.partner?.answers_9month[index2S4]?.questionId}${index}`}
                                              name="radio1"
                                              class="d-none"
                                              checked={
                                                item ===
                                                user?.answers_9month[index2S4]
                                                  ?.answer
                                              }
                                            />
                                            <label
                                              for={`radio1${user?.partner?.answers_9month[index2S4]?.questionId}${index}`}
                                            >
                                              {item}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </form>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {user?.gold_answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            Intake Plan's Question & Answer by {user.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {user?.gold_answers?.map((item, index) => {
                                return (
                                  <button
                                    class={
                                      index === index3
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    id={`nav-${index}-tab`}
                                    data-bs-toggle="tab"
                                    data-bs-target={`#nav-${index}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`nav-${index}`}
                                    aria-selected="true"
                                    onClick={() => setIndex3(index)}
                                  >
                                    {index + 1}
                                  </button>
                                );
                              })}
                            </div>
                          </nav>
                          {user.gold_answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index3}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index3}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      user?.gold_answers[index3]?.questionId
                                        ?.question
                                    }
                                  </h3>
                                  <label>
                                    {user?.gold_answers[index3]?.answer.length >
                                    1
                                      ? user?.gold_answers[index3]?.answer.map(
                                          (item, index) => {
                                            return (
                                              <>
                                                <label>{item}</label> <br />
                                              </>
                                            );
                                          }
                                        )
                                      : user?.gold_answers[index3]?.answer}
                                  </label>
                                </form>
                                {user?.gold_answers[index3]?.answer2 ? (
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.gold_answers[index3]?.questionId
                                          ?.question2
                                      }
                                    </h3>
                                    <label>
                                      {user?.gold_answers[index3]?.answer2}
                                    </label>
                                  </form>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {user?.partner?.gold_answers?.length ? (
                    <div class="col-12 design_outter_comman recent_orders shadow mb-4">
                      <div class="row comman_header justify-content-between">
                        <div class="col">
                          <h2>
                            {" "}
                            Intake Plan's Question & Answer by{" "}
                            {user?.partner?.type}
                          </h2>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 user_tabbing py-4 px-4">
                          <nav>
                            <div
                              class="nav nav-tabs d-flex justify-content-start border-0"
                              id="nav-tab"
                              role="tablist"
                            >
                              {user?.partner.gold_answers?.map(
                                (item, index) => {
                                  return (
                                    <button
                                      class={
                                        index === index4
                                          ? "nav-link active"
                                          : "nav-link"
                                      }
                                      id={`nav-${index}-tab`}
                                      data-bs-toggle="tab"
                                      data-bs-target={`#nav-${index}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`nav-${index}`}
                                      aria-selected="true"
                                      onClick={() => setIndex4(index)}
                                    >
                                      {index + 1}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </nav>
                          {user?.partner?.gold_answers ? (
                            <div class="tab-content mt-4" id="nav-tabContent">
                              <div
                                class="tab-pane fade show active"
                                id={`nav-${index4}`}
                                role="tabpanel"
                                aria-labelledby={`nav-${index4}-tab`}
                              >
                                <form class="row question_ansbox justify-content-center">
                                  <h3 class="mb-4">
                                    {
                                      user?.partner.gold_answers[index4]
                                        ?.questionId?.question
                                    }
                                  </h3>

                                  <label>
                                    {user?.partner?.gold_answers[index4]?.answer
                                      .length > 1
                                      ? user?.partner?.gold_answers[
                                          index4
                                        ]?.answer.map((item, index) => {
                                          return (
                                            <>
                                              <label>{item}</label> <br />
                                            </>
                                          );
                                        })
                                      : user?.partner?.gold_answers[index4]
                                          ?.answer}
                                  </label>
                                </form>
                                {user?.partner?.gold_answers[index4]
                                  ?.answer2 ? (
                                  <form class="row question_ansbox justify-content-center">
                                    <h3 class="mb-4">
                                      {
                                        user?.partner?.gold_answers[index4]
                                          ?.questionId?.question2
                                      }
                                    </h3>
                                    <label>
                                      {
                                        user?.partner?.gold_answers[index4]
                                          ?.answer2
                                      }
                                    </label>
                                  </form>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="modal-body text-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close"
              ></button>
              <div class="row">
                <div class="col-12 Update_modal_content text-center py-4">
                  <h2>Change</h2>
                  <p>Are you sure, Want to Change role?</p>
                  <Link
                    class="comman_btn2 mx-2"
                    onClick={() => swapRole()}
                    data-bs-dismiss="modal"
                    to=""
                  >
                    Yes
                  </Link>
                  <Link class="comman_btn mx-2" data-bs-dismiss="modal" to="">
                    No
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDetails;
